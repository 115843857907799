<template>
    <div id="formDados">
        <b-container fluid>
            <div class="btnLimpar"> 
                <b-row >
                    <b-col cols="2">
                        <b-button class="py-0"
                            variant="outline-primary" 
                            size="sm"
                            @click="reset"
                        >   
                            Limpar
                        </b-button>
                    </b-col>
                </b-row>
            </div>
            <b-row>
                <b-col>
                    <b-form-input
                        v-model="idade" 
                        id="input-idade" 
                        :state="null" 
                        placeholder="Idade"
                        type="number"
                        min=0
                    ></b-form-input>
                </b-col>
                <b-col cols="2" class="p-2">
                    <span>anos</span>
                </b-col>
                <b-col>
                    <b-form-input
                        v-model="peso"  
                        id="input-peso" 
                        :state="null" 
                        placeholder="Peso"
                        type="number"
                        min=0
                    ></b-form-input>
                </b-col>
                <b-col cols="2" class="p-2">
                    <span>Kg</span>
                </b-col>
            </b-row>
            
            <b-row class="pt-1">
                <b-col cols="3" class="pt-2">
                    <span class="pl-1 valor-item">Sexo: </span>
                </b-col>
                <b-col cols="9" class="p-2">
                    <b-form-radio-group
                        v-model="sexo"
                        :options="options"
                        class="mb-3 valor-item"
                        value-field="item"
                        text-field="name"
                        disabled-field="notEnabled"
                    ></b-form-radio-group>
                </b-col>
            </b-row>
            
            <b-row class="mb-4">
                <b-col>
                    <b-form-select 
                        v-model="selectedMedicacao" 
                        :options="optionsMedicacao"
                        @change="calcularMedicacao"
                    ></b-form-select>
                    <!-- <div class="mt-3">Selected: <strong>{{ selectedMedicacao }}</strong></div> -->
                </b-col>
            </b-row>

            <div v-if="resultado">
                <h6 align="center"><strong>Resultado</strong></h6>

                <div v-if="medicacaoCalculada.tipo_saida === 1">
                    <table class="table table-sm table-striped">
                        <thead>
                            <tr>
                                <td>Item</td>
                                <td>Valor(es)</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="rotulo-item">Dose mg/Kg</td>
                                <td class="linha-item">
                                    <div class="valor-item" v-if="medicacaoCalculada.dose_mg_kg.menor">{{medicacaoCalculada.dose_mg_kg.menor}}</div>
                                    <div class="valor-item" v-if="medicacaoCalculada.dose_mg_kg.unica">{{medicacaoCalculada.dose_mg_kg.unica}}</div>
                                    <div class="valor-item" v-if="!medicacaoCalculada.dose_mg_kg.unica"> - </div>
                                    <div class="valor-item" v-if="medicacaoCalculada.dose_mg_kg.maior">{{medicacaoCalculada.dose_mg_kg.maior}}</div>
                                </td>
                            </tr>
                            <tr>
                                <td class="rotulo-item">Tomadas Diárias</td>
                                <td class="linha-item">
                                    <div class="valor-item" v-if="medicacaoCalculada.tomadas">{{medicacaoCalculada.tomadas}}</div>
                                </td>
                            </tr>
                            <tr>
                                <td class="rotulo-item">Apresentação mg/ml</td>
                                <td class="linha-item">
                                    <div class="valor-item" v-if="medicacaoCalculada.apresentacao">{{medicacaoCalculada.apresentacao}}</div>
                                </td>
                            </tr>
                            <tr>
                                <td class="rotulo-item">Dose (mg)</td>
                                <td class="linha-item">
                                    <div class="valor-item" v-if="medicacaoCalculada.dose_mg.menor">{{ parseFloat(medicacaoCalculada.dose_mg.menor.toFixed(3))}}</div>
                                    <div class="valor-item" v-if="medicacaoCalculada.dose_mg.unica">{{ parseFloat(medicacaoCalculada.dose_mg.unica.toFixed(3))}}</div>
                                    <div class="valor-item" v-if="!medicacaoCalculada.dose_mg.unica"> - </div>
                                    <div class="valor-item" v-if="medicacaoCalculada.dose_mg.maior">{{ parseFloat(medicacaoCalculada.dose_mg.maior.toFixed(3))}}</div>
                                </td>
                            </tr>
                            <tr>
                                <td class="rotulo-item">Qtd (mL) da medicação</td>
                                <td class="linha-item">
                                    <div class="valor-item" v-if="medicacaoCalculada.qtde_medicacao.menor">{{ parseFloat(medicacaoCalculada.qtde_medicacao.menor.toFixed(3))}}</div>
                                <div class="valor-item" v-if="medicacaoCalculada.qtde_medicacao.unica">{{ parseFloat(medicacaoCalculada.qtde_medicacao.unica.toFixed(3))}}</div>
                                <div class="valor-item" v-if="!medicacaoCalculada.qtde_medicacao.unica"> - </div>
                                <div class="valor-item" v-if="medicacaoCalculada.qtde_medicacao.maior">{{ parseFloat(medicacaoCalculada.qtde_medicacao.maior.toFixed(3))}}</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- <h6 align="center"><strong>Resultado</strong></h6> -->

                <div v-if="medicacaoCalculada.tipo_saida === 2">
                    <table class="table table-sm table-striped mb-0">
                        <thead>
                            <tr>
                                <td>Item</td>
                                <td>Valor(es)</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="rotulo-item">Dose mcg/Kg/mim</td>
                                <td class="linha-item">
                                    <div class="valor-item" v-if="medicacaoCalculada.dose_mcg_kg_min.menor">{{parseFloat(medicacaoCalculada.dose_mcg_kg_min.menor).toFixed(2)}}</div>
                                    <div class="valor-item" v-if="medicacaoCalculada.dose_mcg_kg_min.unica">{{parseFloat(medicacaoCalculada.dose_mcg_kg_min.unica).toFixed(2)}}</div>
                                    <div class="valor-item" v-if="medicacaoCalculada.dose_mcg_kg_min.maior">{{parseFloat(medicacaoCalculada.dose_mcg_kg_min.maior).toFixed(2)}}</div>
                                </td>
                            </tr>
                            <tr>
                                <td class="rotulo-item">Dose em 24mL</td>
                                <td class="linha-item">
                                    <div class="valor-item" v-if="medicacaoCalculada.dose_mcg_kg_min.menor">{{parseFloat(medicacaoCalculada.dose_mcg_kg_min.menor * peso * 60 * 24).toFixed(0)}}</div>
                                    <div class="valor-item" v-if="medicacaoCalculada.dose_mcg_kg_min.unica">{{parseFloat(medicacaoCalculada.dose_mcg_kg_min.unica * peso * 60 * 24).toFixed(0)}}</div>
                                    <div class="valor-item" v-if="medicacaoCalculada.dose_mcg_kg_min.maior">{{parseFloat(medicacaoCalculada.dose_mcg_kg_min.maior * peso * 60 * 24).toFixed(0)}}</div>
                                </td>
                            </tr>
                            <tr>
                                <td class="rotulo-item">Apresentação mg/ml</td>
                                <td class="linha-item">
                                    <div class="valor-item" v-if="medicacaoCalculada.apresentacao">{{medicacaoCalculada.apresentacao}}</div>
                                </td>
                            </tr>
                            
                        </tbody>
                    </table>
                    <b-row>
                        <b-col cols="12" align="center" class="sub-titulo-itens">
                            <span>Diluição 1ml/h/24h</span>
                        </b-col>
                    </b-row>
                    <table class="table table-sm table-striped">
                        <tbody>
                            <tr>
                                <td class="rotulo-item"><span class="pl-1 rotulo-item">{{medicacaoCalculada.nome}} </span></td>
                                <td class="linha-item">
                                    <div class="valor-item" v-if="medicacaoCalculada.dose_mcg_kg_min.menor">{{ parseFloat((medicacaoCalculada.dose_mcg_kg_min.menor * peso * 60 * 24) / medicacaoCalculada.apresentacao / 1000).toFixed(2) }}</div>
                                    <div class="valor-item" v-if="medicacaoCalculada.dose_mcg_kg_min.unica">{{ parseFloat((medicacaoCalculada.dose_mcg_kg_min.unica * peso * 60 * 24) / medicacaoCalculada.apresentacao / 1000).toFixed(2) }}</div>
                                    <div class="valor-item" v-if="medicacaoCalculada.dose_mcg_kg_min.maior">{{ parseFloat((medicacaoCalculada.dose_mcg_kg_min.maior * peso * 60 * 24) / medicacaoCalculada.apresentacao / 1000).toFixed(2) }}</div>
                                </td>
                            </tr>
                            <tr>
                                <td class="rotulo-item"><span class="pl-1 rotulo-item">SF 0,9% </span></td>
                                <td class="linha-item">
                                    <div class="valor-item" v-if="medicacaoCalculada.dose_mcg_kg_min.menor">{{ parseFloat((medicacaoCalculada.dose_mcg_kg_min.menor * peso * 60 * 24) / medicacaoCalculada.apresentacao / 1000).toFixed(2) }}</div>
                                    <div class="valor-item" v-if="medicacaoCalculada.dose_mcg_kg_min.unica">{{ parseFloat((medicacaoCalculada.dose_mcg_kg_min.unica * peso * 60 * 24) / medicacaoCalculada.apresentacao / 1000).toFixed(2) }}</div>
                                    <div class="valor-item" v-if="medicacaoCalculada.dose_mcg_kg_min.maior">{{ parseFloat((medicacaoCalculada.dose_mcg_kg_min.maior * peso * 60 * 24) / medicacaoCalculada.apresentacao / 1000).toFixed(2) }}</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>                
                </div>



                
                <!-- <div v-if="medicacaoCalculada.tipo_saida === 1">
                    <b-row>
                        <b-col cols="6" class="pt-2">
                            <span class="pl-1 rotulo-item">Dose mg/Kg: </span>
                        </b-col>
                        <b-col cols="6" class="pt-2 doses">
                            <div class="valor-item" v-if="medicacaoCalculada.dose_mg_kg.menor">{{medicacaoCalculada.dose_mg_kg.menor}}</div>
                            <div class="valor-item" v-if="medicacaoCalculada.dose_mg_kg.unica">{{medicacaoCalculada.dose_mg_kg.unica}}</div>
                            <div class="valor-item" v-if="!medicacaoCalculada.dose_mg_kg.unica"> - </div>
                            <div class="valor-item" v-if="medicacaoCalculada.dose_mg_kg.maior">{{medicacaoCalculada.dose_mg_kg.maior}}</div>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="6" class="pt-2">
                            <span class="pl-1 rotulo-item">Tomadas Diárias: </span>
                        </b-col>
                        <b-col cols="6" class="pt-2 doses">
                            <div class="valor-item" v-if="medicacaoCalculada.tomadas">{{medicacaoCalculada.tomadas}}</div>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="6" class="pt-2">
                            <span class="pl-1 rotulo-item">Apresentação mg/ml </span>
                        </b-col>
                        <b-col cols="6" class="pt-2 doses">
                            <div class="valor-item" v-if="medicacaoCalculada.apresentacao">{{medicacaoCalculada.apresentacao}}</div>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="6" class="pt-2">
                            <span class="pl-1 rotulo-item">Dose (mg): </span>
                        </b-col>
                        <b-col cols="6" class="pt-2 doses">
                            <div class="valor-item" v-if="medicacaoCalculada.dose_mg.menor">{{ parseFloat(medicacaoCalculada.dose_mg.menor.toFixed(3))}}</div>
                            <div class="valor-item" v-if="medicacaoCalculada.dose_mg.unica">{{ parseFloat(medicacaoCalculada.dose_mg.unica.toFixed(3))}}</div>
                            <div class="valor-item" v-if="!medicacaoCalculada.dose_mg.unica"> - </div>
                            <div class="valor-item" v-if="medicacaoCalculada.dose_mg.maior">{{ parseFloat(medicacaoCalculada.dose_mg.maior.toFixed(3))}}</div>
                        </b-col>
                    </b-row>

                    <b-row>
                        <b-col cols="6" class="pt-2">
                            <span class="pl-1 rotulo-item">Qtd (mL) da medicação: </span>
                        </b-col>
                        <b-col cols="6" class="pt-2 doses">
                            <div class="valor-item" v-if="medicacaoCalculada.qtde_medicacao.menor">{{ parseFloat(medicacaoCalculada.qtde_medicacao.menor.toFixed(3))}}</div>
                            <div class="valor-item" v-if="medicacaoCalculada.qtde_medicacao.unica">{{ parseFloat(medicacaoCalculada.qtde_medicacao.unica.toFixed(3))}}</div>
                            <div class="valor-item" v-if="!medicacaoCalculada.qtde_medicacao.unica"> - </div>
                            <div class="valor-item" v-if="medicacaoCalculada.qtde_medicacao.maior">{{ parseFloat(medicacaoCalculada.qtde_medicacao.maior.toFixed(3))}}</div>
                        </b-col>
                    </b-row>
                </div>
                <div v-if="medicacaoCalculada.tipo_saida === 2">
                    
                    <b-row>
                        <b-col cols="6" class="pt-2">
                            <span class="pl-1 rotulo-item">Dose mcg/Kg/mim: </span>
                        </b-col>
                        <b-col cols="6" class="pt-2 doses">
                            <div class="valor-item" v-if="medicacaoCalculada.dose_mcg_kg_min.menor">{{parseFloat(medicacaoCalculada.dose_mcg_kg_min.menor).toFixed(2)}}</div>
                            <div class="valor-item" v-if="medicacaoCalculada.dose_mcg_kg_min.unica">{{parseFloat(medicacaoCalculada.dose_mcg_kg_min.unica).toFixed(2)}}</div>
                            <div class="valor-item" v-if="medicacaoCalculada.dose_mcg_kg_min.maior">{{parseFloat(medicacaoCalculada.dose_mcg_kg_min.maior).toFixed(2)}}</div>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6" class="pt-2">
                            <span class="pl-1 rotulo-item">Dose em 24mL: </span>
                        </b-col>
                        <b-col cols="6" class="pt-2 doses">
                            <div class="valor-item" v-if="medicacaoCalculada.dose_mcg_kg_min.menor">{{parseFloat(medicacaoCalculada.dose_mcg_kg_min.menor * peso * 60 * 24).toFixed(0)}}</div>
                            <div class="valor-item" v-if="medicacaoCalculada.dose_mcg_kg_min.unica">{{parseFloat(medicacaoCalculada.dose_mcg_kg_min.unica * peso * 60 * 24).toFixed(0)}}</div>
                            <div class="valor-item" v-if="medicacaoCalculada.dose_mcg_kg_min.maior">{{parseFloat(medicacaoCalculada.dose_mcg_kg_min.maior * peso * 60 * 24).toFixed(0)}}</div>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6" class="pt-2">
                            <span class="pl-1 rotulo-item">Apresentação mg/ml </span>
                        </b-col>
                        <b-col cols="6" class="pt-2 doses">
                            <div class="valor-item" v-if="medicacaoCalculada.apresentacao">{{medicacaoCalculada.apresentacao}}</div>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="12" class="pt-3 pb-2" align="center">
                            <h6 class="pl-1"><strong>Diluição 1ml/h/24h</strong></h6>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6" class="pt-2">
                            <span class="pl-1 rotulo-item">{{medicacaoCalculada.nome}}: </span>
                        </b-col>
                        <b-col cols="6" class="pt-2 doses">
                            <div class="valor-item" v-if="medicacaoCalculada.dose_mcg_kg_min.menor">{{ parseFloat((medicacaoCalculada.dose_mcg_kg_min.menor * peso * 60 * 24) / medicacaoCalculada.apresentacao / 1000).toFixed(2) }}</div>
                            <div class="valor-item" v-if="medicacaoCalculada.dose_mcg_kg_min.unica">{{ parseFloat((medicacaoCalculada.dose_mcg_kg_min.unica * peso * 60 * 24) / medicacaoCalculada.apresentacao / 1000).toFixed(2) }}</div>
                            <div class="valor-item" v-if="medicacaoCalculada.dose_mcg_kg_min.maior">{{ parseFloat((medicacaoCalculada.dose_mcg_kg_min.maior * peso * 60 * 24) / medicacaoCalculada.apresentacao / 1000).toFixed(2) }}</div>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6" class="pt-2">
                            <span class="pl-1 rotulo-item">SF 0,9%:</span>
                        </b-col>
                        <b-col cols="6" class="pt-2 doses">
                            <div class="valor-item" v-if="medicacaoCalculada.dose_mcg_kg_min.menor">{{ parseFloat(24 - ((medicacaoCalculada.dose_mcg_kg_min.menor * peso * 60 * 24) / medicacaoCalculada.apresentacao / 1000)).toFixed(1) }}</div>
                            <div class="valor-item" v-if="medicacaoCalculada.dose_mcg_kg_min.unica">{{ parseFloat(24 - ((medicacaoCalculada.dose_mcg_kg_min.unica * peso * 60 * 24) / medicacaoCalculada.apresentacao / 1000)).toFixed(1) }}</div>
                            <div class="valor-item" v-if="medicacaoCalculada.dose_mcg_kg_min.maior">{{ parseFloat(24 - ((medicacaoCalculada.dose_mcg_kg_min.maior * peso * 60 * 24) / medicacaoCalculada.apresentacao / 1000)).toFixed(1) }}</div>
                        </b-col>
                    </b-row>
                </div> -->
                <div v-if="medicacaoCalculada.tipo_saida === 3">
                    <b-row>
                        <b-col cols="6" class="pt-2">
                            <span class="pl-1 rotulo-item">{{medicacaoCalculada.nome}}</span>
                        </b-col>
                        <b-col cols="6" class="pt-2 doses">
                            <div class="valor-item">{{ parseFloat(((idade/4)+4)-0.5).toFixed(1) }}</div>
                            <div class="valor-item">{{ parseFloat((idade/4)+4).toFixed(1) }}</div>
                            <div class="valor-item">{{ parseFloat(((idade/4)+4)+0.5).toFixed(1) }}</div>
                        </b-col>
                    </b-row>
                </div>

                <div v-if="medicacaoCalculada.tipo_saida === 4">

                    <table class="table table-sm table-striped">
                        <tbody>
                            <tr>
                                <td class="rotulo-item">{{medicacaoCalculada.ordem.n1}}</td>
                                <td class="rotulo-item">{{ medicacaoCalculada.pesoOrdem.p1 }}</td>
                            </tr>
                            <tr>
                                <td class="rotulo-item">{{medicacaoCalculada.ordem.n2}}</td>
                                <td class="rotulo-item">{{ medicacaoCalculada.pesoOrdem.p2 }}</td>
                            </tr>
                            <tr>
                                <td class="rotulo-item">{{medicacaoCalculada.ordem.n3}}</td>
                                <td class="rotulo-item">{{ medicacaoCalculada.pesoOrdem.p3 }}</td>
                            </tr>
                            <tr>
                                <td class="rotulo-item">{{medicacaoCalculada.ordem.n4}}</td>
                                <td class="rotulo-item">{{ medicacaoCalculada.pesoOrdem.p4 }}</td>
                            </tr>
                            <tr>
                                <td class="rotulo-item">{{medicacaoCalculada.ordem.n5}}</td>
                                <td class="rotulo-item">{{ medicacaoCalculada.pesoOrdem.p5 }}</td>
                            </tr>
                            <tr>
                                <td class="rotulo-item">{{medicacaoCalculada.ordem.n6}}</td>
                                <td class="rotulo-item">{{ medicacaoCalculada.pesoOrdem.p6 }}</td>
                            </tr>
                            <tr>
                                <td class="rotulo-item">{{medicacaoCalculada.ordem.n7}}</td>
                                <td class="rotulo-item">{{ medicacaoCalculada.pesoOrdem.p7 }}</td>
                            </tr>
                            <tr>
                                <td class="rotulo-item">{{medicacaoCalculada.ordem.n8}}</td>
                                <td class="rotulo-item">{{ medicacaoCalculada.pesoOrdem.p8 }}</td>
                            </tr>
                        </tbody>
                    </table>


                    <!-- <b-row>
                        <b-col cols="6" class="pt-2">
                            <span class="pl-1 rotulo-item">{{medicacaoCalculada.ordem.n1}} </span>
                        </b-col>
                        <b-col cols="6" class="pt-2 doses">
                            <div class="valor-item">{{ medicacaoCalculada.pesoOrdem.p1 }}</div>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6" class="pt-2">
                            <span class="pl-1 rotulo-item">{{medicacaoCalculada.ordem.n2}} </span>
                        </b-col>
                        <b-col cols="6" class="pt-2 doses">
                            <div class="valor-item">{{ medicacaoCalculada.pesoOrdem.p2 }}</div>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6" class="pt-2">
                            <span class="pl-1 rotulo-item">{{medicacaoCalculada.ordem.n3}} </span>
                        </b-col>
                        <b-col cols="6" class="pt-2 doses">
                            <div class="valor-item">{{ medicacaoCalculada.pesoOrdem.p3 }}</div>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6" class="pt-2">
                            <span class="pl-1 rotulo-item">{{medicacaoCalculada.ordem.n4}} </span>
                        </b-col>
                        <b-col cols="6" class="pt-2 doses">
                            <div class="valor-item">{{ medicacaoCalculada.pesoOrdem.p4 }}</div>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6" class="pt-2">
                            <span class="pl-1 rotulo-item">{{medicacaoCalculada.ordem.n5}} </span>
                        </b-col>
                        <b-col cols="6" class="pt-2 doses">
                            <div class="valor-item">{{ medicacaoCalculada.pesoOrdem.p5 }}</div>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6" class="pt-2">
                            <span class="pl-1 rotulo-item">{{medicacaoCalculada.ordem.n6}} </span>
                        </b-col>
                        <b-col cols="6" class="pt-2 doses">
                            <div class="valor-item">{{ medicacaoCalculada.pesoOrdem.p6 }}</div>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6" class="pt-2">
                            <span class="pl-1 rotulo-item">{{medicacaoCalculada.ordem.n7}} </span>
                        </b-col>
                        <b-col cols="6" class="pt-2 doses">
                            <div class="valor-item">{{ medicacaoCalculada.pesoOrdem.p7 }}</div>
                        </b-col>
                    </b-row>
                    <b-row>
                        <b-col cols="6" class="pt-2">
                            <span class="pl-1 rotulo-item">{{medicacaoCalculada.ordem.n1}} </span>
                        </b-col>
                        <b-col cols="6" class="pt-2 doses">
                            <div class="valor-item">{{ medicacaoCalculada.pesoOrdem.p1 }}</div>
                        </b-col>
                    </b-row> -->
                </div>
                <hr>
            </div>
            <div v-else class="msg">
                {{ msg }}
                <hr>
            </div>
            <!-- <div class="mt-3">Selected: <strong>{{ selected }}</strong></div> -->
            <!-- <div>{{ listMedicacao[0].dose_mg_kg }}</div>-->
            
            <!-- <pre>{{medicacaoCalculada}}</pre> -->
        </b-container>
    </div>
</template>

<script>
export default {
    data() {
        return {
            idade:null,
            peso:null,
            sexo: 'F',
            msg:'',
            resultado: false,
            indices: [ 1, 2, 3, 4, 5, 6, 7 ],
            options: [
                { item: 'F', name: 'Feminino' },
                { item: 'M', name: 'Maculino' },
            ],
            selectedMedicacao: null,
            medicacaoCalculada: [],
            optionsMedicacao: [
                { value: null, text: 'Selecione a Medicação' },
                { value: 1, text: 'Adrenalina (PCR)' },
                { value: 2, text: 'Atropina' },
                { value: 3, text: 'Amoxicilina + Clav' },
                { value: 4, text: 'Cefazolina'},
                { value: 5, text: 'Cefuroxima'},
                { value: 6, text: 'Gentamicina'},
                { value: 7, text: 'Metronidazol'},
                { value: 8, text: 'Midazolam'},
                { value: 9, text: 'Fentanil'},
                { value: 10, text: 'Lidocaína'},
                { value: 11, text: 'Propofol'},
                { value: 12, text: 'Atracúrio'},
                { value: 13, text: 'Cisatracúrio'},
                { value: 14, text: 'Succinilcolina'},
                { value: 15, text: 'Dexametasona'},
                { value: 16, text: 'Hidrocortisona'},
                { value: 17, text: 'Efedrina'},
                { value: 18, text: 'Fenilefrina'},
                { value: 19, text: 'Cetoprofeno (>12 anos)'},
                { value: 20, text: 'Dipirona'},
                { value: 21, text: 'Ondansetrona'},
                { value: 22, text: 'Neostigmina'},
                { value: 23, text: 'Dobutamina'},
                { value: 24, text: 'Adrenalina'},
                { value: 25, text: 'Milrinona'},
                { value: 26, text: 'Tubos Orotraqueais'},
                { value: 27, text: 'Máscara Laríngea'},

            ],
            listMedicacao:[
                {
                    id:1,
                    nome:'Adrenalina (PCR)',
                    dose_mg_kg: { menor: null, unica: 0.01, maior: null },
                    tomadas: null,
                    apresentacao: 1,
                    dose_mg: [{menor: null, unica: null, maior: null}],
                    qtde_medicacao: [{menor: null, unica: null, maior: null}],
                    tipo_saida:1,
                },
                {
                    id:2,
                    nome:'Atropina',
                    dose_mg_kg: { menor: null, unica: 0.02, maior: null },
                    tomadas: null,
                    apresentacao: 0.5,
                    dose_mg: [{menor: null, unica: null, maior: null}],
                    qtde_medicacao: [{menor: null, unica: null, maior: null}],
                    tipo_saida:1,
                },
                {
                    id:3,
                    nome:'Amoxixilina + Clav',
                    dose_mg_kg: { menor: null, unica: 50, maior: null },
                    tomadas: 3,
                    apresentacao: 100,
                    dose_mg: [{menor: null, unica: null, maior: null}],
                    qtde_medicacao: [{menor: null, unica: null, maior: null}],
                    tipo_saida:1,
                },
                {
                    id:4,
                    nome:'Cefazolina',
                    dose_mg_kg: { menor: 30, unica: null, maior: 50 },
                    tomadas: 3,
                    apresentacao: 100,
                    dose_mg: [{menor: null, unica: null, maior: null}],
                    qtde_medicacao: [{menor: null, unica: null, maior: null}],
                    tipo_saida:1,
                },
                {
                    id:5,
                    nome:'Cefuroxima',
                    dose_mg_kg: { menor: null, unica: 30, maior: null },
                    tomadas: 3,
                    apresentacao: 75,
                    dose_mg: [{menor: null, unica: null, maior: null}],
                    qtde_medicacao: [{menor: null, unica: null, maior: null}],
                    tipo_saida:1,
                },
                {
                    id:6,
                    nome:'Gentamicina',
                    dose_mg_kg: { menor: 3, unica: null, maior: 5 },
                    tomadas: 1,
                    apresentacao: 40,
                    dose_mg: [{menor: null, unica: null, maior: null}],
                    qtde_medicacao: [{menor: null, unica: null, maior: null}],
                    tipo_saida:1,
                },
                {
                    id:7,
                    nome:'Metronidazol',
                    dose_mg_kg: { menor: 10, unica: null, maior: 15 },
                    tomadas: 3,
                    apresentacao: 5,
                    dose_mg: [{menor: null, unica: null, maior: null}],
                    qtde_medicacao: [{menor: null, unica: null, maior: null}],
                    tipo_saida:1,
                },
                {
                    id:8,
                    nome:'Midazolam',
                    dose_mg_kg: { menor: 0.1, unica: null, maior: 0.3 },
                    tomadas: null,
                    apresentacao: 1,
                    dose_mg: [{menor: null, unica: null, maior: null}],
                    qtde_medicacao: [{menor: null, unica: null, maior: null}],
                    tipo_saida:1,
                },
                {
                    id:9,
                    nome:'Fentanil',
                    dose_mg_kg: { menor: 0.003, unica: null, maior: 0.005 },
                    tomadas: null,
                    apresentacao: 0.05,
                    dose_mg: [{menor: null, unica: null, maior: null}],
                    qtde_medicacao: [{menor: null, unica: null, maior: null}],
                    tipo_saida:1,
                },
                {
                    id:10,
                    nome:'Lidocaína',
                    dose_mg_kg: { menor: 1, unica: null, maior: 1.5 },
                    tomadas: null,
                    apresentacao: 20,
                    dose_mg: [{menor: null, unica: null, maior: null}],
                    qtde_medicacao: [{menor: null, unica: null, maior: null}],
                    tipo_saida:1,
                },
                {
                    id:11,
                    nome:'Propofol',
                    dose_mg_kg: { menor: 2, unica: null, maior: 5 },
                    tomadas: null,
                    apresentacao: 10,
                    dose_mg: [{menor: null, unica: null, maior: null}],
                    qtde_medicacao: [{menor: null, unica: null, maior: null}],
                    tipo_saida:1,
                },
                {
                    id:12,
                    nome:'Atracúrio',
                    dose_mg_kg: { menor: null, unica: 0.5, maior: null },
                    tomadas: null,
                    apresentacao: 10,
                    dose_mg: [{menor: null, unica: null, maior: null}],
                    qtde_medicacao: [{menor: null, unica: null, maior: null}],
                    tipo_saida:1,
                },
                {
                    id:13,
                    nome:'Cisatracúrio',
                    dose_mg_kg: { menor: null, unica: 0.15, maior: null },
                    tomadas: null,
                    apresentacao: 2,
                    dose_mg: [{menor: null, unica: null, maior: null}],
                    qtde_medicacao: [{menor: null, unica: null, maior: null}],
                    tipo_saida:1,
                },
                {
                    id:14,
                    nome:'Succinilcolina',
                    dose_mg_kg: { menor: null, unica: 1, maior: null },
                    tomadas: null,
                    apresentacao: 10,
                    dose_mg: [{menor: null, unica: null, maior: null}],
                    qtde_medicacao: [{menor: null, unica: null, maior: null}],
                    tipo_saida:1,
                },
                {
                    id:15,
                    nome:'Dexametasona',
                    dose_mg_kg: { menor: null, unica: 0.15, maior: null },
                    tomadas: null,
                    apresentacao: 4,
                    dose_mg: [{menor: null, unica: null, maior: null}],
                    qtde_medicacao: [{menor: null, unica: null, maior: null}],
                    tipo_saida:1,
                },
                {
                    id:16,
                    nome:'Dexametasona',
                    dose_mg_kg: { menor: null, unica: 1, maior: null },
                    tomadas: null,
                    apresentacao: 20,
                    dose_mg: [{menor: null, unica: null, maior: null}],
                    qtde_medicacao: [{menor: null, unica: null, maior: null}],
                    tipo_saida:1,
                },
                {
                    id:17,
                    nome:'Efedrina',
                    dose_mg_kg: { menor: 0.1, unica: null, maior: 0.3 },
                    tomadas: null,
                    apresentacao: 5,
                    dose_mg: [{menor: null, unica: null, maior: null}],
                    qtde_medicacao: [{menor: null, unica: null, maior: null}],
                    tipo_saida:1,
                },
                {
                    id:18,
                    nome:'Fenilefrina',
                    dose_mg_kg: { menor: 0.001, unica: null, maior: 0.003 },
                    tomadas: null,
                    apresentacao: 0.04,
                    dose_mg: [{menor: null, unica: null, maior: null}],
                    qtde_medicacao: [{menor: null, unica: null, maior: null}],
                    tipo_saida:1,
                },
                {
                    id:19,
                    nome:'Cetoprofeno (>12 anos)',
                    dose_mg_kg: { menor: null, unica: 1, maior: null },
                    tomadas: null,
                    apresentacao: 20,
                    dose_mg: [{menor: null, unica: null, maior: null}],
                    qtde_medicacao: [{menor: null, unica: null, maior: null}],
                    tipo_saida:1,
                },
                {
                    id:20,
                    nome:'Dipirona',
                    dose_mg_kg: { menor: 30, unica: null, maior: 50 },
                    tomadas: null,
                    apresentacao: 500,
                    dose_mg: [{menor: null, unica: null, maior: null}],
                    qtde_medicacao: [{menor: null, unica: null, maior: null}],
                    tipo_saida:1,
                },
                {
                    id:21,
                    nome:'Ondansetrona',
                    dose_mg_kg: { menor: 0.05, unica: null, maior: 0.15 },
                    tomadas: null,
                    apresentacao: 2,
                    dose_mg: [{menor: null, unica: null, maior: null}],
                    qtde_medicacao: [{menor: null, unica: null, maior: null}],
                    tipo_saida:1,
                },
                {
                    id:22,
                    nome:'Neostigmina',
                    dose_mg_kg: { menor: 0.04, unica: null, maior: 0.07 },
                    tomadas: null,
                    apresentacao: 0.5,
                    dose_mg: [{menor: null, unica: null, maior: null}],
                    qtde_medicacao: [{menor: null, unica: null, maior: null}],
                    tipo_saida:1,
                },
                {
                    id:23,
                    nome:'Dobutamina',
                    dose_mcg_kg_min: { menor: 5, unica: 10, maior: 20 },
                    apresentacao: 12.5,
                    tipo_saida:2,
                },
                {
                    id:24,
                    nome:'Adrenalina',
                    dose_mcg_kg_min: { menor: 0.1, unica: 0.5, maior: 1 },
                    apresentacao: 1,
                    tipo_saida:2,
                },
                {
                    id:25,
                    nome:'Milrinona',
                    dose_mcg_kg_min: { menor: 0.3, unica: 0.5, maior: 0.7 },
                    apresentacao: 1,
                    tipo_saida:2,
                },
                {
                    id:26,
                    nome:'Tubos Orotraqueais',
                    tipo_saida:3,
                },
                {
                    id:27,
                    nome:'Máscara Laríngea',
                    ordem:{ 
                        n1: 1,
                        n2: 1.5,
                        n3: 2,
                        n4: 2.5,
                        n5: 3,
                        n6: 4,
                        n7: 5,
                    },
                    pesoOrdem:{
                        p1: '<5kg',
                        p2: '5-10kg',
                        p3: '10-20kg',
                        p4: '20-30kg',
                        p5: '30-70kg',
                        p6: '70-90kg',
                        p7: '>90kg',
                    },
                    tipo_saida:4,
                },
            ],
        }
    },
    watch:{
        idade(){
            if(!this.idade){
                this.resultado = false
                this.selectedMedicacao = null
            }
            if(this.idade < 0){
                this.idade = null
            }

            this.msg=''
        },
        peso(){
            if(!this.peso){
                this.resultado = false
                this.selectedMedicacao = null
            }
            if(this.peso < 0){
                this.peso = null
            }
            this.msg=''
        }
    },
    methods:{
        calcularMedicacao(){
            this.msg = ''

            if(!(this.idade && this.peso)){
                this.resultado = false
                this.selectedMedicacao = null
                this.msg = "Idade e/ou peso não informados. Informe a idade e o peso para prosseguir."
            } else {
                this.resultado = true
                // const i = this.idade
                const p = this.peso
                // const s = this.sexo
                const medicacao_id = this.selectedMedicacao
                const med = this.listMedicacao.filter(e => e.id == medicacao_id)
                this.medicacaoCalculada = med[0]

                if(this.medicacaoCalculada.tipo_saida === 1){
                    this.medicacaoCalculada.dose_mg.menor = this.medicacaoCalculada.dose_mg_kg.menor * p
                    this.medicacaoCalculada.dose_mg.maior = this.medicacaoCalculada.dose_mg_kg.maior * p

                    if(this.medicacaoCalculada.tomadas){
                        this.medicacaoCalculada.dose_mg.unica = (this.medicacaoCalculada.dose_mg_kg.unica * p) / this.medicacaoCalculada.tomadas
                    } else {
                        this.medicacaoCalculada.dose_mg.unica = this.medicacaoCalculada.dose_mg_kg.unica * p
                    }    

                    this.medicacaoCalculada.qtde_medicacao.menor = this.medicacaoCalculada.apresentacao ? this.medicacaoCalculada.dose_mg.menor / this.medicacaoCalculada.apresentacao  : null
                    this.medicacaoCalculada.qtde_medicacao.unica = this.medicacaoCalculada.apresentacao ? this.medicacaoCalculada.dose_mg.unica / this.medicacaoCalculada.apresentacao  : null
                    this.medicacaoCalculada.qtde_medicacao.maior = this.medicacaoCalculada.apresentacao ? this.medicacaoCalculada.dose_mg.maior / this.medicacaoCalculada.apresentacao  : null
                }
            }
        },
        reset(){
            this.idade = ''
            this.peso = ''
            this.sexo = 'F'
        }
    },
}
</script>

<style scope>
    .input-idade{
        display:flex;
        justify-content: center;
    }
    .doses{
        display: flex;
        justify-content: space-between;
    }
    .rotulo-item{
        font-size: 0.8rem;
    }
    .valor-item{
        font-size: 0.8rem;
    }
    .msg{
        font-size: 0.9rem;
        color:brown;
    }
    .valor-item > div > label{
        padding-top: 4px;
    }
    .btnLimpar .row{
        display:flex;
        flex-direction: row;
        justify-content:flex-end;
        align-items: center; 
        min-height: 25px;
        padding:0px;
        margin: 0px;
        margin-bottom: 10px;
    }
    .linha-item{
        display:flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .sub-titulo-itens{
        display:flex;
        justify-content: center;
        padding:0.1rem;
        font-size: 0.9rem;;
    }
</style>