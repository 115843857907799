<template>
    <footer class="footer">
        <a href="https://ideianinja.com.br">
            <img class="ideia-footer" src="@/assets/ideia-footer.png" alt="Logo Ideia Ninja">
        </a>
    </footer>
</template>

<script>
export default {
    name: 'Footer'
}
</script>

<style>
    .footer{
        grid-area: footer;
        background: #3e746c;
        color:#333;
        display: flex;
        justify-content: center ;
        align-items: center;
        font-size:0.8rem;
        padding: 15px
    }
    .footer > a:hover
    {
        text-decoration: none;
    }
    .footer .ideia-footer{
        width: 90px;
    }
</style>