<template>
    <div class="content">
		<transition name="slide" mode="out-in">
			<Ped />
		</transition>
    </div>
</template>

<script>
import Ped from '../Ped'
export default {
	name: 'Content',
	components: { Ped }
}
</script>

<style>
    .content{
        grid-area: content;
        background-color: #efefef;
        padding:5px;
    }

    @keyframes slide-in {
		from { transform: translateY(-30px); opacity: 0;}
		to { transform: translateY(0px); opacity: 1;}
	}
	@keyframes slide-out {
		from { transform: translateY(0px); opacity: 1;}
		to { transform: translateY(-30px); opacity: 0;}
	}

	.slide-enter-active{
		animation: slide-in 0.3s ease;
	}

	.slide-leave-active{
		animation: slide-out 0.3s ease;
	}

</style>