<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <Header title="Medicação Pediatria" />
		<Content />
		<Footer />
  </div>
</template>

<script>
import Header from "@/components/template/Header"
import Content from "@/components/template/Content"
import Footer from "@/components/template/Footer"

export default {
  name: 'App',
  components: {
    Header,
    Content,
    Footer
  }
}
</script>

<style>
body {
		margin: 0;
	}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  /* text-align: center; */
  color: #2c3e50;
  /* margin-top: 60px; */

  height: 100vh;
  display: grid;
		grid-template-rows: 60px 1fr 40px;
		grid-template-columns: 1fr;
		grid-template-areas:
			"header"
			"content"
			"footer";
}

@media (max-width: 1600px) {  
  #app {
    padding: 0px 400px;
  }
} 


@media (max-width: 1200px) {  
  #app {
    padding: 0px 300px;
  }
} 

@media (max-width: 999px) {  
  #app {
    padding: 0px 200px;
  }
} 


@media (max-width: 768px) {  
  #app {
    padding: 0px 100px;
  }
}

@media (max-width: 736px) {  
  #app {
    padding: 0px 0px;
  }
}
@media (max-width: 640px) {  
  #app {
    padding: 0px 0px;
  }
}

@media (max-width: 576px) {  
  #app {
    padding: 0px 0px;
  }
}
</style>
