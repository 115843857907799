<template>
    <header class="header">
        <b-icon 
            icon="file-spreadsheet-fill" 
            class="h3 mb-2"
            style="color: white;"
        ></b-icon>
        <h5>Medicação Pediatria</h5>
        <!-- <button 
            type="button" 
            aria-label="Close" 
            class="close"
            @click="closeWindow"
        >×</button> -->
    </header>
</template>

<script>
export default {
    name: 'Header',
}
</script>

<style>
    .header{
        grid-area: header;
        /* background: linear-gradient(to right, #1e469a, #49a7c1); */
        background: linear-gradient(to right, #3e746c, #3e746c);
        display: flex;
        justify-content:space-between;
        align-items: center;
        margin-bottom: 5px;
        padding: 0px 15px;
        color:aliceblue;
    }
    header .close{
        color:white;
        opacity: 1;
    }

    header .close:hover{
        color:whitesmoke
    }

</style>

